import React from "react"

const SubPageServicesSubTitle = (props) => {
    const titleHtml=[];
    const spaceBaseClass="w-3 bg-head-services md:w-6 xl:w-8";
    titleHtml.push(<span key={props.id + '_span_st'}  className={spaceBaseClass + " inline-block"}>&nbsp;</span>);
    for(var i in props.titles) {
        if(i !== '0') {
            titleHtml.push(<span key={props.id + '_span_2_' + i} className={spaceBaseClass + " " + props.titlesBrClass}>&nbsp;</span>);
            titleHtml.push(<br key={props.id + '_br_' + i} className={props.titlesBrClass}/>);
            titleHtml.push(<span key={props.id + '_span_3_' + i} className={spaceBaseClass + " " + props.titlesBrClass}>&nbsp;</span>);
        }
        titleHtml.push(<span key={props.id + '_span_1_' + i} className="
           inline-block bg-head-services mb-3
           md:mb-4
        ">{props.titles[i]}</span>);
    }
    titleHtml.push(<span key={props.id + '_span_end'}  className={spaceBaseClass + " inline-block"}>&nbsp;</span>);
    return (
        <div className={`
            mb-2
            md:mb-9
            ${props.className ? props.className : ''}
        `} style={props.style}>
            <h3 className='
                text-18px tracking-50em leading-27 font-thin 
                md:text-40px md:leading-49px
                xl:text-47px xl:leading-65px
            '>{titleHtml}</h3>
            {props.subTitle && <h4 className="
                text-14px tracking-50em leading-none font-thin mb-3
                md:text-28px md:mb-4
                xl:text-26px
            ">{props.subTitle}</h4>}
        </div>
    );
};

export default SubPageServicesSubTitle;