import React from "react"
import { AnimLeft } from "src/components/animation.js"

const SubPageServicesSubTitleSystem = (props) => {
    const titleHtml = [];
    titleHtml.push(<span key={props.id + '_span_st'} className="
        inline-block w-3 bg-head-services
        md:hidden
    ">&nbsp;</span>);
    for (var i in props.titles) {
        titleHtml.push(<span key={props.id + '_span_' + i} className="
           inline-block bg-head-services mb-3
           md:block md:mb-4 md:pl-6
        ">{props.titles[i]}</span>);
    }
    titleHtml.push(<span key={props.id + '_span_end'} className="
        inline-block w-3 bg-head-services
        md:hidden
    ">&nbsp;</span>);
    return (
        <div className={`
            mb-2
            md:mb-9
            ${props.className ? props.className : ''}
        `} style={props.style}>
            <AnimLeft>
                <h3 className='
                    text-18px tracking-50em leading-27 font-thin 
                    md:text-40px md:leading-49px
                    xl:text-47px xl:leading-65px
                '>{titleHtml}</h3>
                {props.subTitle && <h4 className="
                    text-14px tracking-50em leading-none font-thin mb-3
                    md:text-28px md:mb-4
                    xl:text-26px
                ">{props.subTitle}</h4>}
            </AnimLeft>
        </div>
    );
};

export default SubPageServicesSubTitleSystem;