import React from "react"
import SubPageServicesBook from "src/components/subpage/services/book"

const SubPageServicesBookList = (props) => {
    let listHtml = [];

    for (var i in props.bookList) {
        listHtml.push(
            <SubPageServicesBook key={'bkList_' + i} id={'bkList_book_' + i} title={props.bookList[i].title} dsc={props.bookList[i].dsc} img={props.bookList[i].img} className="
                w-full pb-20
                md:w-1/2 md:px-10 md:pb-28
                xl:w-1/3 xl:px-9
            "/>
        );
    }
    return (
        <div className={`
            flex flex-wrap flex-row mx-auto
            md:-mx-10
            xl:-mx-9
            ${props.className ? props.className : ''}
        `} style={props.style}>
            {listHtml}
        </div>
    );
};

export default SubPageServicesBookList;