import React from "react"
import { BREAKPOINTS } from "src/utils/exportFunctions"

const StylesServices = (props) => {
    return (
        <style>{`


        @media (min-width: ${BREAKPOINTS['md']}px) {

        }

        @media (min-width: ${BREAKPOINTS['lg']}px) {
        }

        @media (min-width: ${BREAKPOINTS['xl']}px) {

        }
        `}
        </style>
    );
};

export default StylesServices;