import React from "react"
import { BREAKPOINTS } from "src/utils/exportFunctions"

const StylesSubpageServicesH2Title = (props) => {
    return (
        <style>{`
        #Services #section_event h2 {
            width: 60.81%;
            max-width: 358px;
        }
        #Services #section_magazine h2 {
            width: 100%;
            max-width: 595px;
        }
        #Services #section_system h2 {
            width: 75.37%;
            max-width: 444px;
        }
        #Services #section_others h2 {
            width: 74.8%;
            max-width: 441px;
        }


        @media (min-width: ${BREAKPOINTS['md']}px) {
            #Services #section_event h2 {
                width: 358px;
                max-width: initial;
            }
            #Services #section_magazine h2 {
                width: 595px;
                max-width: initial;
            }
            #Services #section_system h2 {
                width: 444px;
                max-width: initial;
            }
            #Services #section_others h2 {
                width: 441px;
                max-width: initial;
            }
        }

        @media (min-width: ${BREAKPOINTS['lg']}px) {
        }

        @media (min-width: ${BREAKPOINTS['xl']}px) {
            #Services #section_event h2 {
                width: 413px;
            }
            #Services #section_magazine h2 {
                width: 686px;
            }
            #Services #section_system h2 {
                width: 512px;
            }
            #Services #section_others h2 {
                width: 508px;
            }
        }
        `}
        </style>
    );
};

export default StylesSubpageServicesH2Title;