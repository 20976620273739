import * as React from "react"
import Layout from "src/components/layout"
import Meta from "src/components/meta"
import { PAGE_IDS } from "src/utils/exportFunctions"
import SubPageMv from "src/components/subpage/mv"
import SubPageServicesDetailBlock from "src/components/subpage/services/detailBlock"
import SubPageServicesDetailBlockSystem from "src/components/subpage/services/detailBlockSystem"
import SubPageServicesCircleList from "src/components/subpage/services/circleList"
import SubPageServicesBookList from "src/components/subpage/services/bookList"
import SubPageServicesOthersBlock from "src/components/subpage/services/othersBlock"
import SubPageServicesH2Title from "src/components/subpage/services/h2Title"
import SubPageServicesImgList from "../components/subpage/services/imgList"
import titleEventImg from 'src/images/services/services_title_event.svg'
import titleMgznImg from 'src/images/services/services_title_magazine.svg'
import titleOtrsImg from 'src/images/services/services_title_others.svg'
import titleSysImg from 'src/images/services/services_title_system.svg'
import StylesSubpageMv from "src/components/styles/subpage/mv"
import StylesServices from "src/styles/services"
import StylesSubpageServicesH2Title from "src/components/styles/subpage/services/h2Title"
import { AnimUp } from "src/components/animation.js"

const ServicesPage = () => {
    const anchorList = [
        { 'text': '相談会', 'anchorId': 'section_event' },
        { 'text': '大学情報誌', 'anchorId': 'section_magazine' },
        { 'text': '情報システム関連', 'anchorId': 'section_system' },
        { 'text': 'その他の事業', 'anchorId': 'section_others' },
    ];

    const eventImgList = [
        { 'text': '新潟　朱鷺メッセ', 'imgId': '1' },
        { 'text': '東京　サンシャインシティ', 'imgId': '2' },
        { 'text': '静岡　ツインメッセ静岡', 'imgId': '3' },
        { 'text': '大阪　グランフロント大阪', 'imgId': '4' },
        { 'text': '広島　広島産業会館', 'imgId': '5' }
    ];

    const bookList = [
        {
            'title': ['人を笑顔にする仕事BOOK'],
            'dsc': '将来の進路を真剣に考える高校生に、「人を笑顔にする仕事とは」「自分らしくイキイキと学べる進路とは何なのか」を考えるきっかけを与え、なかでも“人の役に立ち、人を笑顔にする仕事”の代表格といえる、医療・福祉分野を特集し、仕事の魅力や資格について、さらには大学での学びの特色を紹介します。',
            'img': { 'filename': 'services_book_1.jpg' },
        },
        {
            'title': ['保護者のための大学進学ガイド'],
            'dsc': '近年の入試改革や大学進学に関する動向をはじめとする最新受験情報のほか、大卒者の就職状況、子どもの大学受験における保護者の役割、受験生活サポート情報、さらに進学にかかる費用や学費ローンといったマネー情報まで、保護者がいま知りたい情報を実例を交えながら紹介します。',
            'img': { 'filename': 'services_book_2.jpg' },
        },
        {
            'title': ['大学研究マッチングブック'],
            'dsc': '「大学研究」をテーマに、高校生が大学進学を主体的にとらえられるように作られた情報誌です。一般的な進路指導の方法である、将来の目標（職業や資格）を定めてから、逆算して学部や大学を選ぶやり方ではなく、高校生が現時点から将来自立して社会に出るまでの間に、どこを伸ばし、何ができるのかを考えさせる「積み上げ式」の大学選びを提案します。',
            'img': { 'filename': 'services_book_3.jpg' },
        },
        {
            'title': ['「食」にかかわる', '学びと仕事BOOK'],
            'dsc': '在学生による「学び」紹介、卒業生による「仕事」紹介をメインとしながら、PB商品や中食ブーム、レシピサイト 、（農林水産業の）六次産業化などの「食」にかかわる最新トピックを扱い、従来にない「食」という切り口で、高校生が将来の進路・キャリアを描くことをサポートするためのガイドブックです。',
            'img': { 'filename': 'services_book_4.jpg' },
        },
        {
            'title': ['どうなる！大学入試'],
            'dsc': '併願作戦をはじめ、私大の全学部統一入試や一般選抜、共通テスト利用入試の受験ポイント、その年度の大学別入試変更点、新増設情報、奨学金・特待生入試など、受験につながる最新情報を掲載しています。',
            'img': { 'filename': 'services_book_5.jpg' },
        },
        {
            'title': ['C-Style'],
            'dsc': '高校生は、各大学のアウトラインについて受験情報誌やWebサイトなどから豊富に情報を得ています。ただ、それほど多くの情報を得ていながらも、「高校と大学の学びはどう違うのか」など、キャンパスライフを具体的にイメージできず不安を抱えている人も少なくないでしょう。　本誌は、そんな高校生の気持ちに応えるべく、現役大学生の「ナマの声」や「本音」などがふんだんに盛り込まれた「リアルライフマガジン」です。',
            'img': { 'filename': 'services_book_6.jpg' }
        },
    ];

    return (
        <Layout>
            <Meta
                title="事業内容"
                desc="ライオン企画のアクセスページです"
            />
            <StylesServices />
            <StylesSubpageServicesH2Title />
            <div id="Services">
                <StylesSubpageMv />
                <SubPageMv pageId={PAGE_IDS['services']} anchorList={anchorList} />

                <div className="
                    w-sm mx-auto
                    md:w-md
                    xl:w-xl xl:max-w-1000px
                ">
                    <section id="section_event" className="
                        mb-56
                        md:mb-96
                        xl:mb-72
                    ">
                        <SubPageServicesH2Title img={titleEventImg} alt='相談会' />
                        <SubPageServicesDetailBlock
                            titles={['大学フェア']}
                            details={[
                                "「大学フェア」は、1987年の東京での初開催以来35年近く受験生の進路選択の場として、また大学の個性をアピールする場として発展してきた進学相談会です。",
                                "一方で、近年では“大学全入”時代を迎えつつあり、大学進学を取り巻く環境は大きく変化しています。こうした時代の変化に対応して、「大学フェア」は開催当初からの、大学受験を前提としたモチベーションの高い受験生のための相談会スタイルを堅持し続けながら、多様なコーナーを設けて高校生の将来の進路・キャリア発見の場となっています。"
                            ]}
                            className="mb-11 md:mb-1.5"
                            id="fair"
                        />
                        <SubPageServicesImgList
                            eventImgList={eventImgList}
                            imgPrefix="services_event_"
                            imgExt="jpg"
                        />

                        <SubPageServicesDetailBlock
                            titles={['ココロとカラダを考える', '進学相談会']}
                            titlesBrClass="inline-block xl:hidden"
                            subTitle='（医療・福祉分野が学べる大学の進学相談会）'
                            details={[
                                "「医療分野」、「福祉分野」のスペシャリストをめざす高校生を対象とした進学相談会です。",
                                "個別相談、資料配布のほか、進学へのモチベーションをより高めるための「学び体験コーナー」や、各種講演会も実施します。また、当相談会のテーマ、対象分野に関わる学びや資格、仕事に関する情報をわかりやすく編集し、相談会参加校の情報なども掲載した「人を笑顔にする仕事BOOK」も、来場者に配付します。"
                            ]}
                            className="mb-8 md:mb-16 xl:mb-4"
                            id="consultation"
                        />
                        <SubPageServicesCircleList
                            texts={[
                                { 'title': '大阪', 'subTitle': '(北)' },
                                { 'title': '大阪', 'subTitle': '(南)' },
                                { 'title': '名古屋' },
                            ]}
                            className="mb-24 md:mb-48 xl:mb-40"
                        />

                        <SubPageServicesDetailBlock
                            titles={['大学進学セミナー']}
                            details={[
                                "開催エリアおよび大都市の主要私立大学・国公立大学が一堂に集結する進学相談会です。気になる大学の教職員や在学生と話をしたり、さまざまな受験対策講座を受講したり、大学進学に役立つホットな情報をたくさん手に入れることができます。",
                            ]}
                            className="mb-8 md:mb-16 xl:mb-4"
                            id="seminar"
                        />
                        <SubPageServicesCircleList
                            texts={[
                                { 'title': '高崎' },
                                { 'title': '甲府' },
                                { 'title': '東京' },
                            ]}
                            className="mb-24 md:mb-48 xl:mb-40"
                        />

                        <SubPageServicesDetailBlock
                            titles={['「大学へ行こう！」進学相談会']}
                            details={[
                                "大学と専門学校の違い、大学で学べること、大学の授業の進め方やキャリア教育、さらに就職のこと、高校生が大学をイメージするときに抱きがちなこのような疑問に、様々な企画で答える進学相談会です。",
                            ]}
                            className="mb-8 md:mb-16 xl:mb-4"
                            id="consultation2"
                        />
                        <SubPageServicesCircleList
                            texts={[
                                { 'title': '東京' },
                            ]}
                            className="mb-24 md:mb-48 xl:mb-40"
                        />

                        <SubPageServicesDetailBlock
                            titles={['美術・芸術大学進学相談会']}
                            details={[
                                "美術・芸術系の大学への進学を希望する高校生のための進学相談会です。個別相談ブース以外にも参画大学の作品展示、美術・芸術系進学予備校スタッフによる特別講演、美大受験特別相談コーナー、デッサン体験コーナー等も設置し、受験生の様々なニーズに対応します。",
                            ]}
                            className="mb-8 md:mb-16 xl:mb-4"
                            id="consultation3"
                        />
                        <SubPageServicesCircleList
                            texts={[
                                { 'title': '東京' },
                            ]}
                            className="mb-2 md:mb-48 xl:mb-20"
                        />
                        <AnimUp>
                            <div className="
                                text-center
                                text-15px tracking-05em leading-27px font-thin 
                                md:text-18px md:leading-33px md:font-light
                                xl:text-28px xl:leading-50px
                            ">
                                <h4 className="
                                    mx-auto border-b border-services
                                    w-96
                                    md:w-365px
                                    xl:w-550px
                                ">その他</h4>
                                <p>首都圏女子大学進学相談会</p>
                                <p>関西著名女子大学進学相談会</p>
                            </div>
                        </AnimUp>
                    </section>

                    <section id="section_magazine" className="
                        mb-40
                        md:mb-80
                        xl:mb-40
                    ">
                        <SubPageServicesH2Title img={titleMgznImg} alt='大学情報誌' />
                        <SubPageServicesBookList bookList={bookList} />
                    </section>

                    <section id="section_system" className="
                        mb-56
                        md:mb-80
                        xl:mb-56
                    ">
                        <SubPageServicesH2Title img={titleSysImg} alt='情報システム関連' />
                        <SubPageServicesDetailBlockSystem
                            titles={['E楽ネット', '出願']}
                            details={[
                                "インターネット出願を利用した出願受付は一般的となり、 大多数の大学で採用され、導入することは必須の情勢となりつつあります。 開発期間が長く、運用コストも高価格な既存システムが多い中、 E楽ネット出願は基本的な入試内容をパッケージ化し、 導入価格を大きく引き下げたシステムとして開発いたしました。 準備期間が短く安価のため、インターネット出願を低価格で 導入したいという大学様には、最適なシステムとなっております。",
                            ]}
                            className="mb-16 md:mb-24 xl:mb-40"
                            id="eRaku"
                        />
                        <SubPageServicesDetailBlockSystem
                            titles={['E楽Plus']}
                            details={[
                                "インターネット出願を利用した出願受付を行うにあたり、各大学様が抱える固有の事情（紙願書廃止の可否、写真のデータ化の可否、他のWebサービスとの連携から学内システムとの連携の必要性など）に寄り添うべくカスタマイズし易く設計されたシステムにより、貴学の入試受付処理のルーチンへのフィットを可能としています。また、時代の流れとしてスマートフォンやタブレットでの出願にも対応可能な画面構成とし、より多くの環境での出願登録作業に支障が出ないよう配慮した画面構成を採用しています。 ",
                            ]}
                            className="mb-16 md:mb-24 xl:mb-40"
                            id="eRakuPlus"
                        />
                        <SubPageServicesDetailBlockSystem
                            titles={['合否照会', 'サービス']}
                            details={[
                                "インターネットを利用した合格発表運用に「堅実性」と「柔軟性」を安価に提供するシステムです。",
                            ]}
                            className="mb-16 md:mb-24 xl:mb-40"
                            id="pfService"
                        />
                        <SubPageServicesDetailBlockSystem
                            titles={['Web受験票']}
                            details={[
                                "紙出願とネット出願を併用したままでも、郵送による受験票送付を廃止することの可能なシステムを安価に提供します。",
                            ]}
                            id="webTicket"
                        />
                    </section>

                    <section id="section_others" className="
                        mb-11
                        md:mb-28
                        xl:mb-36
                    ">
                        <SubPageServicesH2Title img={titleOtrsImg} alt='その他の事業' />
                        <div className="
                            flex flex-col justify-between
                            md:flex-row md:-mx-14
                            xl:-mx-16
                        ">
                            <SubPageServicesOthersBlock
                                title="クリエイティブ事業"
                                dsc="学校案内・入試要項制作／各種映像制作／大学オリジナルグッズ等、その他制作物／Webサイト構築／オープンキャンパス関連業務／入学試験運営補助業務／その他イベント運営／大学独自進学相談会／教員対象入試説明会／各種シンポジウム"
                                className="flex-1 md:px-14 xl:px-16"
                            />
                            <SubPageServicesOthersBlock
                                title="その他実績"
                                dsc="新聞／雑誌広告／リスティング広告／交通広告／発送代行業務／テレビ／ラジオCM／Zoomアカウント取得代行"
                                className="flex-1 md:px-14 xl:px-16"
                            />
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    )
}

export default ServicesPage
