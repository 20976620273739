import React from "react"
import { AnimUp } from "src/components/animation.js"

const SubPageServicesOthersBlock = (props) => {
    return (
        <div className={props.className} style={props.style}>
            <div className="mb-16">
                <AnimUp>
                    <h3 className="
                        text-center border-b border-services
                        text-13px tracking-06em leading-25px font-light
                        md:leading-34px
                       xl:text-17px xl:leading-43px
                    ">{props.title}</h3>
                </AnimUp>
                <AnimUp>
                    <p className="
                        text-justify
                        text-13px tracking-06em leading-25px font-light
                        md:leading-29px
                        xl:text-15px xl:leading-34px
                    ">{props.dsc}</p>
                </AnimUp>
            </div>
        </div>
    );
};

export default SubPageServicesOthersBlock;