import React from "react"
import SubPageServicesSubTitle from "src/components/subpage/services/subTitle";
import SubPageServicesDetail from "src/components/subpage/services/detail";
import { AnimLeft, AnimRight } from "src/components/animation.js"

const SubPageServicesDetailBlock = (props) => {
    let detailHtml = [];
    for (var i in props.details) {
        detailHtml.push(<SubPageServicesDetail key={props.id + '_SubPageServicesDetail_' + i} strings={props.details[i]} />);
    }
    return (
        <div className={props.className} style={props.style}>
            <AnimLeft>
                <SubPageServicesSubTitle id={props.id + '_SubPageServicesSubTitle'} titles={props.titles} titlesBrClass={props.titlesBrClass} subTitle={props.subTitle} />
            </AnimLeft>
            <AnimRight>
                {detailHtml}
            </AnimRight>
        </div>
    );
};

export default SubPageServicesDetailBlock;