import React from "react"
import { AnimUp } from "src/components/animation.js"

const SubPageServicesH2Title = (props) => {
    return (
        <AnimUp>
            <h2 className="
                mx-auto mb-14
                md:mb-24
                xl:mb-32
            "><img src={props.img} alt={props.alt} /></h2>
        </AnimUp>
    );
};

export default SubPageServicesH2Title;