import React from "react"
import Image from "src/components/image";
import { AnimUp } from "src/components/animation.js"

const SubPageServicesBook = (props) => {
    let title = [];
    let altTitle = '';
    for (var i in props.title) {
        if (i !== '0') {
            title.push(<br key={props.id + '_ttl_br_' + i} className="hidden xl:inline" />);
        }
        title.push(<span key={props.id + '_ttl_span_' + i}>{props.title[i]}</span>);
        altTitle += props.title[i];
    }
    return (
        <div key={props.id} className={props.className} style={props.style}>
            <AnimUp>
                <Image className="w-1/3 mx-auto md:w-3/5" filename={props.img.filename} alt={props.img.alt ? props.img.alt : altTitle} />
            </AnimUp>
            <AnimUp>
                <h3 className="
                    text-14px tracking-06em leading-none py-5 font-light text-center
                    md:text-18px  md:tracking-05em md:py-10
                    xl:text-22px  xl:tracking-tight xl:leading-33px xl:pb-0 xl:h-44 xl:pt-12
                ">{title}</h3>
                <div className="
                    w-16 border-b border-separator-services h-0.5 mx-auto mb-4
                    md:w-20 md:mb-8
                    xl:w-28 xl:mb-10
                "></div>
                <p className="
                    text-12px tracking-06em leading-21px text-justify
                    md:text-14px md:leading-29px
                    xl:text-15px xl:leading-34px xl:w-11/12 xl:mx-auto
                ">{props.dsc}</p>
            </AnimUp>
        </div>
    );
};

export default SubPageServicesBook;