import React from "react"
import Image from "src/components/image";
import { AnimUp } from "src/components/animation.js"

const SubPageServicesImgList = (props) => {
    let eventImgHtml = [];
    for (var i in props.eventImgList) {
        eventImgHtml.push(
            <div key={i} className="
                w-full py-4
                md:w-1/2 md:px-10 md:py-14
                xl:w-1/3 xl:px-12 xl:py-10
            ">
                <AnimUp>
                    <Image
                        isUsePicture={true}
                        ext={props.imgExt}
                        filename={props.imgPrefix + props.eventImgList[i].imgId} alt={props.eventImgList[i].text}
                        className="mb-1.5 md:mb-3"
                        sizes={{
                            'width': {'sp': 561, 'tab': 615, 'pc': 580},
                            'height': {'sp': 241, 'tab': 395, 'pc': 374}
                        }}
                    />
                    <p className="
                        text-11px tracking-06em leading-none font-light
                        md:text-15px
                    ">{props.eventImgList[i].text}</p>
                </AnimUp>
            </div>
        );
    }
    return (
        <div className="
            flex flex-wrap flex-row mb-20
            md:-mx-10 md:mb-44
            xl:-mx-12 xl:mb-40
        ">
            {eventImgHtml}
        </div>
    );
};

export default SubPageServicesImgList;