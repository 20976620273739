import React from "react"
import SubPageServicesSubTitleSystem from "src/components/subpage/services/subTitleSystem";
import SubPageServicesDetail from "src/components/subpage/services/detail";
import { AnimRight } from "src/components/animation.js"

const SubPageServicesDetailBlockSystem = (props) => {
    let detailHtml = [];
    for(var i in props.details){
        detailHtml.push(<AnimRight key={props.id + '_AnimRight_' + i}><SubPageServicesDetail key={props.id + '_SubPageServicesDetail_' + i} strings={props.details[i]}/></AnimRight>);
    }
    return (
        <div className={`
            block
            md:flex md:flex-row
            ${props.className ? props.className : ''}
        `} style={props.style}>
            <SubPageServicesSubTitleSystem  id={props.id + '_SubPageServicesSubTitleSystem'} className="
                whitespace-nowrap
                md:pr-11
                xl:pr-13
            " style={{'flexBasis': '320px'}}
            titles={props.titles} titlesBrClass={props.titlesBrClass} subTitle={props.subTitle}/>
            <div className="
                md:-mt-5              
            " style={{'flexBasis': 'calc(100% - 320px)'}}>
                {detailHtml}
            </div>
        </div>
    );
};

export default SubPageServicesDetailBlockSystem;