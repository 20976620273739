import React from "react"

const SubPageServicesDetail = (props) => {
    return (
        <p className="
            text-13px tracking-06em leading-25px font-light text-justify
            md:text-17px md:leading-43px
            xl:text-18px xl:leading-44px
        ">{props.strings}</p>
    );
};

export default SubPageServicesDetail;