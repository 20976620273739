import React from "react"
import { AnimUp } from "src/components/animation.js"

const SubPageServicesCircleList = (props) => {
    let listHtml = [];
    let wrapCircleClass = "w-full mb-8";
    const animDelay = 200;
    if (props.texts.length > 1) {
        wrapCircleClass = `
            w-1/2 mb-8
            md:w-1/3  
        `;
    }
    const textClass = `
        text-10px tracking-06em leading-6 font-light
        md:text-18px md:leading-10
    `;

    for (var i in props.texts) {
        listHtml.push(
            <div key={'crclList_div_' + i} className={wrapCircleClass}>
                <AnimUp delay={animDelay * i}>
                    <div key={i} className="
                        mx-auto flex flex-col items-center justify-center rounded-full border-circle-services
                        w-32 h-32 border-2
                        md:w-52 md:h-52 md:border-6
                    ">
                        <p className={textClass}>{props.texts[i].title}</p>
                        {props.texts[i].subTitle && <p className={textClass}>{props.texts[i].subTitle}</p>}
                    </div>
                </AnimUp>
            </div>
        );
    }
    return (
        <div className={`
            flex flex-wrap flex-row mx-auto
            w-9/12 max-w-xs
            md:w-7/12 md:max-w-4xl
            ${props.className ? props.className : ''}
        `} style={props.style}>
            {listHtml}
        </div>
    );
};

export default SubPageServicesCircleList;